@import "variables";
@import "mixins";
@import "keyframes";
@import "modules/header";
@import "modules/static-hero";
@import "modules/hero";
@import "modules/timer";
@import "modules/about";
@import "modules/cinema-form";
@import "modules/map";
@import "modules/hosts";
@import "modules/partners";
@import "modules/contact";
@import "modules/video";

@import "components/mobile-menu";
@import "components/nav-list";
@import "components/layer";
@import "components/loader";

@import "atoms/confetti";

html,
body {
    position: relative;
    font-family: $font-primary;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: $color-grey;
    scroll-behavior: smooth;
}

section {
    overflow-x: hidden;
}

h1,
h2,
h3 {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

.mb-default {
    margin-bottom: 70px;

    @include min-m {
        margin-bottom: 100px;
    }

    @include min-xxl {
        margin-bottom: 100px;
    }
}

.form__msg {
    margin-top: 1.2em;
}

.side-dots__nav-link.selected {
    background-color: $color-black;
}

.container {
    width: 80%;
    height: 100%;
    margin: 0 auto;

    &--fullpage {
        width: 100%;
        max-width: 100%;
    }
}

@include min-m {
    .container {
        max-width: 700px;

        &--fullpage {
            width: 100%;
            max-width: 100%;
        }
    }
}

@include min-xl {
    .container {
        max-width: 1024px;

        &--fullpage {
            width: 100%;
            max-width: 100%;
        }
    }
}

@include min-xxl {
    .container {
        max-width: 1600px;

        &--fullpage {
            width: 100%;
            max-width: 100%;
        }
    }
}

.slide-down {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    padding: 30px;
    z-index: 7;
    cursor: pointer;
    transition: 0.3s bottom ease-out 0.3s, 0.1s opacity ease-out;
    opacity: 1;

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: url("../images/arrow-down.png") no-repeat;
        background-position: center;
        background-size: contain;
        width: 40px;
        height: 30px;
    }
}

.side-dots {
    display: none;
}

@include min-xl {
    .slide-down {
        bottom: 32vh;
        left: calc(15% + 19px);

        &:hover {
            &::before {
                animation: arrow 1s ease-out forwards infinite;
            }
        }

        &::before {
            margin-top: 50px;
            width: 50px;
            height: 45px;
        }

        &.is-hidden {
            bottom: -130px;
            transition-delay: 0s;
        }
    }

    .side-dots {
        display: block;
        position: fixed;
        top: 50%;
        right: -30px;
        transform: translate(-100%, -50%);
        z-index: 100;

        &__nav-item {
            list-style: none;
            margin-right: 20px;
            margin-bottom: 10px;
            display: block;
            border-radius: 50%;
            box-sizing: border-box;
            width: 12px;
            height: 12px;
            border: 2px $color-black solid;
            background-color: transparent;
            outline: none;
            opacity: 1;
            transition: 0.2s all ease-out;

            &:hover {
                border: 6px $color-black solid;
            }

            &.swiper-pagination-bullet-active {
                background-color: $color-black;
            }
        }
    }
}

@include min-xl {
    .side-dots {
        right: -50px;
    }
}

@include min-xxl {
    .side-dots {
        right: -10px;
    }
}

.swiper-container {
    width: 100%;

    background-color: $color-white;

    @include min-l {
        height: 100%;
        position: fixed;
        overflow: hidden;
    }

    &.mob-hero-visible {
        overflow: hidden;
        height: 100%;
        background-size: cover;

        .swiper-slide:first-of-type {
            height: 100vh;
            // position: absolute;
            background-image: url("../images/heroBGmob.jpg");
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            z-index: 5;
            overflow: hidden;

            @include min-m {
                background-image: url("../images/heroBG.jpg");
            }
        }
    }

    &.mob-hero-hidden {
        .swiper-slide:first-of-type {
            height: 0;
            // position: absolute;
            background-image: url("../images/heroBGmob.jpg");
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            z-index: 5;
            overflow: hidden;

            @include min-m {
                background-image: url("../images/heroBG.jpg");
            }
        }

        .slide-down {
            bottom: -75px;
            opacity: 0;
        }
    }

    .swiper-wrapper {
        display: block;

        @include min-l {
            display: flex;
        }

        .swiper-slide {
            transition: height 0.4s ease-out;

            &.purple {
                background-color: #433f5d;

                @include min-l {
                    background-color: transparent;
                }
            }
        }
    }
}

#loader-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #ecf0f1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.js .load,
.js #loader-wrapper {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
}

input {
    width: 100%;
    box-sizing: border-box;
    color: white;
    padding: 17px 20px;
    border: 1px #8e8c9e solid;
    border-radius: 8px;
    background-color: rgba(90, 86, 113, 0.6);

    &::placeholder {
        color: white;
    }
}

input[type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    + label {
        display: inline-block;
        position: relative;
        left: 35px;
        width: calc(100% - 170px);
        line-height: 1.4;

        &::before {
            content: "";
            position: absolute;
            display: inline-block;
            left: -35px;
            top: 0px;
            width: 20px;
            height: 20px;
            // padding: 10px;
            border: 1px #8f8ba4 solid;
            border-radius: 5px;
            background-color: $color-white;
            margin-right: 10px;
            cursor: pointer;
        }

        .is-active & {
            &::before {
                border: 1px #ff5076 solid;
            }
        }
    }

    &:checked + label::before {
        background-color: $color-black;
    }

    .is-active & {
        &:checked + label::before {
            border: 1px #8f8ba4 solid;
        }
    }
}

.spool {
    &__item {
        mix-blend-mode: darken;
        position: absolute;
        width: 250px;
        height: 250px;
        background-size: cover;
        animation: 40s infinite linear spinning;

        &--light {
            opacity: 0.2;
        }

        &--mid {
            opacity: 0.6;
        }

        &--dark-blue {
            background-image: url("../images/spools/spool-marine.svg");
        }

        &--pink {
            background-image: url("../images/spools/spool-pink.svg");
        }

        &--orange {
            background-image: url("../images/spools/spool-orange.svg");
        }

        &:nth-child(1) {
            right: 95%;
            bottom: 67vh;
            width: 117px;
            height: 117px;
        }

        &:nth-child(2) {
            right: 80%;
            bottom: 53vh;
            width: 150px;
            height: 150px;
        }

        &:nth-child(3) {
            right: 54%;
            bottom: 38vh;
            width: 202px;
            height: 202px;
        }

        &:nth-child(4) {
            right: 27%;
            bottom: 36vh;
            width: 225px;
            height: 225px;
        }

        &:nth-child(5) {
            right: 10%;
            bottom: 34vh;
            width: 202px;
            height: 202px;
        }

        &:nth-child(6) {
            right: -5%;
            bottom: 25vh;
            width: 150px;
            height: 150px;
        }

        &:nth-child(7) {
            right: -15%;
            bottom: 15vh;
            width: 117px;
            height: 117px;
        }

        &--bg {
            animation: none;
            right: auto;

            &:nth-child(1) {
                left: 30px;
                top: 17vh;
                width: 44px;
                height: 44px;
            }

            &:nth-child(2) {
                left: -20px;
                bottom: 53vh;
                width: 65px;
                height: 65px;
            }

            &:nth-child(3) {
                right: 20vw;
                bottom: 40vh;
                width: 80px;
                height: 80px;
            }

            &:nth-child(4) {
                right: -20px;
                bottom: -40px;
                width: 84px;
                height: 84px;
            }

            &:nth-child(5) {
                right: -45px;
                bottom: 70vh;
                width: 80px;
                height: 80px;
            }

            &:nth-child(8),
            &:nth-child(6),
            &:nth-child(7) {
                display: none;
            }
        }

        $delay: 2s;

        @for $index from 1 to 8 {
            &:nth-child(#{$index}) {
                animation-delay: $delay;
            }

            $delay: $delay + 0.45s;
        }
    }

    &__list {
        width: 100%;
        height: 100%;
        max-height: 1000px;
        position: relative;
        max-width: 500px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;

        &--bg {
            position: fixed;
            top: 0;
            width: 100vw;
            height: 100vh;
        }

        &::after {
            content: "";
            background-image: url("../images/spools/spool-line.svg");
            background-size: 120% 60%;
            background-repeat: no-repeat;
            background-position: bottom 15vh right 4vh;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }
    }
}

@include min-s {
    .spool {
        &__item {
            &:nth-child(1) {
                right: 420px;
                bottom: 65vh;
                width: 157px;
                height: 157px;
            }

            &:nth-child(2) {
                right: 350px;
                bottom: 53vh;
                width: 180px;
                height: 180px;
            }

            &:nth-child(3) {
                right: 240px;
                bottom: 39vh;
                width: 242px;
                height: 242px;
            }

            &:nth-child(4) {
                right: 80px;
                bottom: 36vh;
                width: 295px;
                height: 295px;
            }

            &:nth-child(5) {
                right: 30px;
                bottom: 34vh;
                width: 242px;
                height: 242px;
            }

            &:nth-child(6) {
                right: -10px;
                bottom: 25vh;
                width: 180px;
                height: 180px;
            }

            &:nth-child(7) {
                right: -60px;
                bottom: 15vh;
                width: 167px;
                height: 167px;
            }

            &--bg {
                &:nth-child(1) {
                    left: 70px;

                    width: 94px;
                    height: 94px;
                }

                &:nth-child(2) {
                    left: 0px;
                    bottom: 33vh;
                    width: 125px;
                    height: 125px;
                }

                &:nth-child(3) {
                    right: 20vw;
                    bottom: 50vh;
                    width: 160px;
                    height: 160px;
                }

                &:nth-child(4) {
                    right: -30px;
                    bottom: -40px;
                    width: 220px;
                    height: 220px;
                }

                &:nth-child(5) {
                    right: -45px;
                    bottom: 70vh;
                    width: 80px;
                    height: 80px;
                }
            }
        }

        &__list {
            width: 100%;
            height: 100%;
            position: relative;
            max-width: 80%;
            float: right;

            &::after {
                height: 60%;
                background-size: 120% 100%;
                background-position: top 0vh right 0px;
            }
        }
    }
}

@include min-m {
    .spool {
        &__item {
            &:nth-child(1) {
                right: 680px;
                bottom: 60vh;
                width: 165px;
                height: 165px;
            }

            &:nth-child(2) {
                right: 550px;
                bottom: 50vh;
                width: 209px;
                height: 209px;
            }

            &:nth-child(3) {
                right: 405px;
                bottom: 33vh;
                width: 287px;
                height: 287px;
            }

            &:nth-child(4) {
                right: 200px;
                bottom: 25vh;
                width: 363px;
                height: 363px;
            }

            &:nth-child(5) {
                right: 125px;
                bottom: 22vh;
                width: 287px;
                height: 287px;
            }

            &:nth-child(6) {
                right: -20px;
                bottom: 12vh;
                width: 224px;
                height: 224px;
            }

            &:nth-child(7) {
                right: -80px;
                bottom: 3vh;
                width: 169px;
                height: 169px;
            }

            &--bg {
                &:nth-child(1) {
                    left: 70px;

                    width: 94px;
                    height: 94px;
                }

                &:nth-child(2) {
                    left: 0px;
                    bottom: 33vh;
                    width: 125px;
                    height: 125px;
                }

                &:nth-child(3) {
                    right: 20vw;
                    bottom: 50vh;
                    width: 160px;
                    height: 160px;
                }

                &:nth-child(4) {
                    right: -30px;
                    bottom: -40px;
                    width: 220px;
                    height: 220px;
                }

                &:nth-child(5) {
                    right: -45px;
                    bottom: 70vh;
                    width: 80px;
                    height: 80px;
                }
            }
        }

        &__list {
            &::after {
                top: 55%;
                background-position: bottom -10vh right 40px;
                height: 80%;
            }
        }
    }
}

@include min-l {
    .spool {
        &__item {
            &--bg {
                transition: transform 1s ease;
            }
        }

        &__list {
            &::after {
                top: 55%;
                background-size: 100% 100%;
                background-position: bottom -10vh right 0px;
                height: 80%;
            }
        }
    }
}

@include min-xl {
    .spool {
        &__item {
            mix-blend-mode: darken;

            &:nth-child(1) {
                right: 920px;
                bottom: 77vh;
                width: 265px;
                height: 265px;
            }

            &:nth-child(2) {
                right: 790px;
                bottom: 53vh;
                width: 384px;
                height: 384px;
            }

            &:nth-child(3) {
                right: 475px;
                bottom: 28vh;
                width: 495px;
                height: 495px;
            }

            &:nth-child(4) {
                right: 240px;
                bottom: 25vh;
                width: 563px;
                height: 563px;
            }

            &:nth-child(5) {
                right: 100px;
                bottom: 18vh;
                width: 497px;
                height: 497px;
            }

            &:nth-child(6) {
                right: -50px;
                bottom: 8vh;
                width: 364px;
                height: 364px;
            }

            &:nth-child(7) {
                right: -80px;
                bottom: 0vh;
                width: 169px;
                height: 169px;
            }

            @media screen and (max-height: 850px) {
                &:nth-child(1) {
                    right: 920px;
                    bottom: 80vh;
                    width: 165px;
                    height: 165px;
                }

                &:nth-child(2) {
                    right: 770px;
                    bottom: 53vh;
                    width: 284px;
                    height: 284px;
                }

                &:nth-child(3) {
                    right: 475px;
                    bottom: 28vh;
                    width: 395px;
                    height: 395px;
                }

                &:nth-child(4) {
                    right: 240px;
                    bottom: 25vh;
                    width: 443px;
                    height: 443px;
                }

                &:nth-child(5) {
                    right: 100px;
                    bottom: 18vh;
                    width: 357px;
                    height: 357px;
                }

                &:nth-child(6) {
                    right: -50px;
                    bottom: 8vh;
                    width: 264px;
                    height: 264px;
                }

                &:nth-child(7) {
                    right: -80px;
                    bottom: 0vh;
                    width: 169px;
                    height: 169px;
                }
            }

            &--bg {
                &:nth-child(1) {
                    left: 70px;

                    width: 94px;
                    height: 94px;
                }

                &:nth-child(2) {
                    left: 0px;
                    bottom: 33vh;
                    width: 125px;
                    height: 125px;
                }

                &:nth-child(3) {
                    right: 20vw;
                    bottom: 50vh;
                    width: 160px;
                    height: 160px;
                }

                &:nth-child(4) {
                    right: -30px;
                    bottom: -40px;
                    width: 220px;
                    height: 220px;
                }

                &:nth-child(5) {
                    right: -45px;
                    bottom: 70vh;
                    width: 80px;
                    height: 80px;
                }

                transition: transform 1s ease;
            }
        }

        &__list {
            width: 100%;
            height: 100%;
            position: relative;
            max-width: 80%;

            &::after {
                background-size: 80vw 100%;
                height: 100%;
                background-position: top 10vh right 45px;
            }
        }
    }
}

@include min-xxl {
    .spool {
        &__list {
            &::after {
                background-size: 80% 100%;
                height: 100%;
                background-position: top 5vh right 0px;
            }
        }
    }
}

@keyframes spinning {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}
